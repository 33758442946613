/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
    var Sage;
    Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                windowsize = $(window).width();
                if (windowsize > 991) {
                    $('.navbar .dropdown').hover(function () {
                        if (!$(this).hasClass('hovered')) {
                            $(this).addClass('hovered').find('.dropdown-menu').show();
                            //$(this).addClass('hovered');
                        }

                    }, function () {
                        if ($(this).hasClass('hovered')) {
                            $(this).removeClass('hovered').find('.dropdown-menu').hide();
                        }
                        //$(this).removeClass('hovered');
                    });
                    $('.navbar li.dropdown a').click(function (e) {
                        e.preventDefault();
                        window.location.href = $(this).attr('href');
                    });
                }
                if ($('.heroslider .item').length > 0) {
                    var $heroslider = $('.heroslider .owl-carousel');

                    $heroslider.owlCarousel({
                        animateOut: 'fadeOut',
                        animateIn: 'fadeIn',
                        center: true,
                        autoplay: true,
                        autoplayTimeout: 7000,
                        //autoplaySpeed: 4000,
                        items: 1,
                        loop: true,
                        margin: 0,
                        dots: true,
                        nav: true,
                        //smartSpeed: 1000,
                        responsiveClass: true,
                        navText: [
                            '<span class="icon-prev"></span>',
                            '<span class="icon-next"></span>'
                        ],

                        dotsContainer: $('.heroslider .carousel-dots')
                    });

                     /*var heroslider_img = $('.heroslider .item').find('img')[0].height;
                    $('.carousel-dots').css('top', heroslider_img + 10);

                    $heroslider.on('resized.owl.carousel', function (e) {
                        heroslider_img = $('.heroslider .item').find('img')[0].height;
                        $('.carousel-dots').css('top', heroslider_img + 10);
                    }); */
                }

                var $productcarousel = $('.productcarousel .owl-carousel');
                $productcarousel.owlCarousel({
                    loop: true,
                    navText: [
                        '<span class="icon-prev"></span>',
                        '<span class="icon-next"></span>'
                    ],
                    responsiveClass: true,
                    margin: 30,
                    responsive: {
                        0: {
                            items: 1,
                            nav: false,
                            dots: true,
                        },
                        768: {
                            items: 3,
                        },
                        830: {
                            nav: true,
                            dots: false,
                        }
                    }

                });

                $(".accordion .panel").on("show.bs.collapse hide.bs.collapse", function (e) {
                    if (e.type == 'show') {
                        $(this).addClass('panel-active');
                    } else {
                        $(this).removeClass('panel-active');
                    }
                });
                $('.accordion').on('shown.bs.collapse', function (e) {
                    var offset = $('.accordion .panel.panel-default.panel-active > .panel-collapse.in').offset();
                    if (offset) {
                        $('html,body').animate({
                            scrollTop: $('.panel-active .panel-title a').offset().top
                        }, 300);
                    }
                });

                if (windowsize < 768) {
                    $('#primary-navigation .dropdown').each(function () {
                        var $button_text = $(this).find('a.dropdown-toggle').text();
                        $(this).find('a.dropdown-toggle').html('<span>' + $button_text + '</span>');
                    });
                    $('#primary-navigation a.dropdown-toggle').on('click', 'span', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.href = $(this).parent().attr('href');
                    });
                }

                $('form.bmw-form select').selectpicker();

                $('.bmw-event-form select').selectpicker();

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $('.btntop').on('click', function(f) {
                  var c = $("html, body");
                  c.stop().animate({
                    scrollTop: 0
                  }, "500", "swing", function() {
                    console.log("Finished scrolling 2 top")
                  });
                });
                //caches a jQuery object containing the header element
                var button = $(".btntop");
                $(window).scroll(function() {
                    var scroll = $(window).scrollTop();

                    if (scroll >= 200) {
                        button.removeClass('hidden').addClass("visible");
                    } else {
                        button.removeClass("visible").addClass('hidden');
                    }
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
